* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.reviews {
    width: 100%;
    margin: 0 auto;
    background: #fff;
    padding: 70px 0;
    position: relative;

    @media screen and (max-width: 1024px) {
        padding: 30px 0;
        padding-bottom: 80px;
    }

    @media screen and (max-width: 1024px) {
        padding-bottom: 80px;
    }

    .container {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
    }

    .heading {
        grid-column: span 12;
        margin-bottom: 50px;

        @media screen and (max-width: 650px) {
            margin-bottom: 10px;
        }

        h2 {
            font-size: 65px;
            position: initial;
            color: #000;
            margin: 0;
            text-align: center;

            @media screen and (max-width: 1260px) {
                font-size: 45px;
            }

            @media screen and (max-width: 992px) {
                text-align: center;
                margin-bottom: 20px;
            }

            @media screen and (max-width: 650px) {
                font-size: 35px;
                margin-bottom: 10px;
            }
        }
    }

    .cards {
        grid-column: span 5;
        display: grid;
        gap: 2rem;

        @media screen and (max-width: 1024px) {
            grid-column: span 12;
            gap: 10px;
            margin: 0;
            margin-bottom: 20px;
        }

        .review-card {
            cursor: pointer;
            padding: 20px;
            border-radius: 1rem;
            display: grid;
            grid-template-columns: auto 1fr;
            column-gap: 2.188rem;
            align-items: center;
            transition: 0.3s;
            position: relative;
            border: 0.094rem solid #0f172a14;

            @media screen and (max-width: 1024px) {
                padding: 20px;
            }

            @media screen and (max-width: 600px) {
                width: 100%;
                padding: 10px;
                grid-template-columns: auto;

                .reviewName {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h3 {
                        margin: 0;
                        width: 50%;
                    }

                    svg {
                        width: 10px;
                        color: #eca633;
                    }
                }
            }

            span {
                display: inline-block;
                transition: 0.5s;
                opacity: 1;

                svg {
                    width: 1.25rem;
                    color: #eca633;
                }
            }

            img {
                display: block;
                width: 3.35rem;
                height: 3.35rem;
                border-radius: 50%;
                filter: grayscale(1);
                transition: 0.5s;
            }

            >div {
                h3 {
                    // text-transform: capitalize;
                    font-size: 1.025rem;
                    background: none;
                    box-shadow: none;
                    letter-spacing: 1px;
                    text-align: left;
                    padding: 0;
                    font-weight: 500;
                }

                p {
                    text-transform: capitalize;
                    color: #767676;
                    font-size: 0.9rem;

                    @media screen and (max-width: 1024px) {
                        margin: 0;
                    }
                }
            }
        }

        .review-card.active {
            background: #fff;
            border: 0.094rem solid #0f172a14;

            .gradient {
                background-image: linear-gradient(to right,
                        #4755690a,
                        #9d0cb28a,
                        #4343c899,
                        #4755690a);
                width: 50%;
                height: 0.094rem;
                position: absolute;
                content: "";
                bottom: -0.063rem;
                left: 50%;
                transform: translateX(-50%);
                box-shadow: 0px 0.125rem 0.75rem #4343c84d;
            }
        }

        .review-card.active img {
            filter: grayscale(0);
        }
    }

    .content {
        grid-column: span 6;
        position: relative;
        width: 100%;
        overflow: inherit;
        // margin-top: 2rem;
        margin-left: 50px;

        @media screen and (max-width: 1024px) {
            grid-column: span 12;
            margin: 0;
        }

        .contentBox {
            // position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: start;

            @media screen and (min-width: 1025px) {
                position: absolute;
            }

            @media screen and (max-width: 1024px) {
                display: none;

                &.active {
                    display: block;
                }
            }

            @media screen and (max-width: 1024px) {
                left: 0;
            }

            .text {
                // padding-bottom: 2rem;

            }

            h2 {
                transition: 0.5s;
                opacity: 0;
                text-align: left;
                width: auto;
                position: initial;
            }

            p {
                transition: 0.5s;
                opacity: 0;
                margin-top: 1.25rem;
            }

            span {
                display: inline-block;
                transition: 0.5s;
                opacity: 0;
                margin-top: 0.625rem;

                svg {
                    width: 1.25rem;
                    color: #eca633;
                }
            }
        }

        .contentBox.active h2 {
            opacity: 1;
            transition-delay: 0.5s;
            letter-spacing: 1px;
        }

        .contentBox.active span {
            opacity: 1;
            transition-delay: 0.7s;
        }

        .contentBox.active p {
            opacity: 1;
            transition-delay: 0.9s;
        }
    }
}

body::-webkit-scrollbar {
    @media screen and (min-width: 650px) {
        width: 0.8em;
    }
}

body::-webkit-scrollbar-track {
    @media screen and (min-width: 650px) {
        box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
    }
}

body::-webkit-scrollbar-thumb {
    @media screen and (min-width: 650px) {
        background-color: #3f3f3f;
    }
}

.pagination {
    font-size: 22px;
    margin: auto;
    margin-top: 10px;

    @media screen and (max-width: 1024px) {
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    a {
        color: #000;
        text-decoration: none;
    }

    li {
        width: 50px;
        height: 50px;
        // background-color: red;
        margin: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        cursor: pointer;
        transition: 0.3s;
        border: 1px solid transparent;

        @media screen and (max-width: 500px) {
            width: 30px;
            height: 30px;

            a {
                font-size: 16px;
            }
        }

        &:hover {
            border: 1px solid #767676;
        }

        a {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }



        &.selected {
            background-color: #02427D;

            a {
                color: #fff;
            }
        }

        &.previous {
            position: relative;
            border: none;

            a {
                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-image: url(./assets/images/left.webp);
                    width: 15px;
                    height: 15px;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;

                    @media screen and (max-width: 500px) {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }

        &.next {
            position: relative;
            border: none;

            a {
                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-image: url(./assets/images/right.webp);
                    width: 15px;
                    height: 15px;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;

                    @media screen and (max-width: 500px) {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }
    }
}