@keyframes move-background {
    from {
        transform: translate3d(0px, 0px, 0px);
    }

    to {
        transform: translate3d(1000px, 0px, 0px);
    }
}

@-webkit-keyframes move-background {
    from {
        transform: translate3d(0px, 0px, 0px);
    }

    to {
        transform: translate3d(1000px, 0px, 0px);
    }
}

@-moz-keyframes move-background {
    from {
        transform: translate3d(0px, 0px, 0px);
    }

    to {
        transform: translate3d(1000px, 0px, 0px);
    }
}

.background-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.background-container img{
    animation-name: rotate2;
    animation-duration: 70s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.background-container .container{
    display: none;
}

@keyframes rotate2 {
    from {
        transform: rotate(180deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.stars {
    display: none;
    background: black url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/stars.png) repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 0;
}

.twinkling {
    width: 10000px;
    height: 100%;
    background: transparent url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/twinkling.png") repeat;
    background-size: 1000px 1000px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    animation: move-background 70s linear infinite;
    -webkit-animation: move-background 70s linear infinite;
    -moz-animation: move-background 70s linear infinite;
    -o-animation: move-background 70s linear infinite;
}

.clouds {
    width: 10000px;
    height: 100%;
    background: transparent url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/clouds_repeat.png") repeat;
    background-size: 1000px 1000px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
    animation: move-background 150s linear infinite;
    -webkit-animation: move-background 150s linear infinite;
    -moz-animation: move-background 150s linear infinite;
    -o-animation: move-background 150s linear infinite;
}

img {
    height: 70vh;
    width: 70vh;
    position: absolute;
    z-index: 3;
    right: 20px;
}


@import 'https://fonts.googleapis.com/css?family=Open+Sans:300|Roboto:400';


div.absolute-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    opacity: 1;
    visibility: visible;

    transition: all .5s;
}

h3,
h5 {
    padding: 10px 30px 10px 30px;
    box-sizing: border-box;

    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0 5px 0 black;

    font-family: 'Open Sans';
    font-weight: 300;
    text-align: center;
    letter-spacing: 3px;
}

span#close {
    position: absolute;
    top: -15px;
    left: -30px;

    color: white;
    font-size: 30px;
    text-shadow: 0 0 5px black;

    cursor: pointer;
}

canvas {
    /* //border: 2px solid; */
}