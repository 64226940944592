.section_numbers {
    z-index: 2;
    position: relative;
    overflow: hidden;
    background-color: #1a1a1a;
    /* padding: 120px 0; */
}

.padding-global {
    -webkit-text-size-adjust: 100%;
    color: #d9d9d9;
    font-size: 1.125rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.container-large {
    -webkit-text-size-adjust: 100%;
    color: #d9d9d9;
    font-size: 1.125rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    width: 100%;
    max-width: 80rem;
    margin-right: auto !important;
    margin-left: auto !important;
}

.padding-section-xlarge {
    -webkit-text-size-adjust: 100%;
    color: #d9d9d9;
    font-size: 1.125rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    padding-top: 10rem;
    padding-bottom: 10rem;

    @media screen and (max-width: 830px) {
        padding: 30px 0;
    }
}

.section_header.is-no-bottom {
    -webkit-text-size-adjust: 100%;
    color: #d9d9d9;
    font-size: 1.125rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    z-index: 1;
    flex-direction: column;
    align-items: center;
    display: flex;
    margin-bottom: 0;
}

.numbers_grid {
    -webkit-text-size-adjust: 100%;
    font-size: 1.125rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-align: center;
    box-sizing: border-box;
    grid-column-gap: 3.25rem;
    color: #fff;
    font-weight: 500;
    display: flex;

    @media screen and (max-width: 830px) {
        flex-wrap: wrap;
    }
}

.numbers_item {
    -webkit-text-size-adjust: 100%;
    font-size: 1.125rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-align: center;
    color: #fff;
    font-weight: 500;
    box-sizing: border-box;
    position: static;

    @media screen and (max-width: 830px) {
        width: 100%;
        margin-bottom: 20px;
    }
}

.numbers_text {
    color: #fff;
    margin-left: 3.5rem;
    font-size: 6rem;
    font-weight: 700;
    line-height: 1.35;
    position: relative;

    @media screen and (max-width: 830px) {
        font-size: 62px;
        margin: 0;
    }

    @media screen and (max-width: 600px) {
        font-size: 40px;
    }
}

.numbers_super.is-v2,
.numbers_super.is-v1,
.numbers_super {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-align: center;
    color: #fff;
    font-weight: 700;
    box-sizing: border-box;
    vertical-align: baseline;
    line-height: 0;
    position: relative;
    top: -.5em;
    height: 4.3rem;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 6rem;
    background-image: url('https://assets.website-files.com/63de80ab03e95f0ae7de2a37/63e15e39604860f92acade1d_mesh-gradient%20\(5\)%202.jpg');
    background-position: 0 0;
    background-size: contain;

    @media screen and (max-width: 830px) {
        height: 40px;
        font-size: 50px;
    }

    @media screen and (max-width: 830px) {
        height: auto;
        font-size: 30px;
    }
}

.text-size-large {
    font-size: 1.5rem;
    line-height: 1.4;

    @media screen and (max-width: 830px) {
        font-size: 18px;
        line-height: 28px;
    }

    @media screen and (max-width: 600px) {
        font-size: 16px;
    }
}

.section_numbers .numbers_screen {
    -webkit-text-size-adjust: 100%;
    color: #d9d9d9;
    font-size: 1.125rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    z-index: -1;
    opacity: .5;
    perspective: 1412px;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    overflow: hidden;

    // @media screen and (max-width: 600px) {
    //     transform: scale(1.7);
    // }
}

.section_numbers .numbers_screen .numbers_bg-grad {
    z-index: 1;
    /* background-image: radial-gradient(circle, rgba(255, 255, 255, .1), transparent 50%); */
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.section_numbers .numbers_screen .numbers_grad {
    z-index: 0;
    width: 290vw;
    height: 50vw;
    transform-origin: 0%;
    transform-style: preserve-3d;
    background-image: linear-gradient(270deg, #1a1a1a 50%, transparent 80%);
    flex: none;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: auto;
    transform: rotate3d(0, 1, .0000949539, 80deg);
}

.section_numbers .numbers_screen .numbers_grad.is-right {
    transform-origin: 100%;
    transform-style: preserve-3d;
    background-image: linear-gradient(90deg, #1a1a1a 50%, transparent 80%);
    top: 0%;
    bottom: 0%;
    left: auto;
    right: 0%;
    transform: rotateX(0)rotateY(-80deg)rotate(0);
}

.numbers_screen-left {
    z-index: -1;
    transform-origin: 0%;
    transform-style: preserve-3d;
    justify-content: flex-start;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: auto;
    transform: rotate3d(0, 1, .0000949539, 80deg);
}

.numbers_screen-right {
    z-index: -1;
    transform-origin: 100%;
    transform-style: preserve-3d;
    justify-content: flex-end;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: auto;
    right: 0%;
    transform: rotateX(0)rotateY(-80deg)rotate(0);
}

.numbers_screen-box-left {
    -webkit-text-size-adjust: 100%;
    color: #d9d9d9;
    // font-family: DM Sans, sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    flex: none;
    display: flex;
    transform: translate(0%, 0px);
    animation-name: bgshots;
    animation-duration: 45s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @media screen and (max-width: 650px) {
        animation-duration: 15s;
    }
}

.numbers_image {
    position: initial;
    width: 40vw;
    perspective: 100px;
    object-fit: contain;
    flex: none !important;
    margin-left: 3rem;
    margin-right: 3rem;
    transform: perspective(100px);
    height: auto;
}

.numbers_screen-box-right {
    -webkit-text-size-adjust: 100%;
    color: #d9d9d9;
    // font-family: DM Sans, sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    flex: none;
    display: flex;
    transform: translate(0%, 0px);
    animation-name: bgshotsright;
    animation-duration: 45s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    
    @media screen and (max-width: 650px) {
        animation-duration: 15s;
    }
}

@keyframes bgshotsright {
    0% {
        transform: translate(0%, 0px);
    }

    100% {
        transform: translate(100%, 0px)
    }
}

@keyframes bgshots {
    0% {
        transform: translate(0%, 0px);
    }

    100% {
        transform: translate(-100%, 0px);
    }
}