.hero_grad-wrapper {
    -webkit-text-size-adjust: 100%;
    color: #d9d9d9;
    font-family: DM Sans, sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    z-index: 1;
    // background-image: radial-gradient(circle at 100% 100%, rgba(255, 255, 255, .8) 10%, transparent 50%);
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    pointer-events: none;
    // background: url(./assets/images/bg.png);
    // background-size: contain;
    // background-repeat: no-repeat;
    background-image: radial-gradient(ellipse closest-side, transparent, rgba(0, 0, 0, 0.849) 50%, black);

    @media screen and (max-width: 650px) {
        background-image: radial-gradient(ellipse closest-side, transparent, rgba(0, 0, 0, 0.849) 90%, black);
    }

}

.hero_image-container {
    -webkit-text-size-adjust: 100%;
    color: #d9d9d9;
    font-family: DM Sans, sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    z-index: 0;
    max-width: 100rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    pointer-events: none;
    align-items: center;
    overflow: hidden;
    transform: scale(1.2);
    width: 100vw;
    overflow: hidden;
}

.background-grid_wrapper {
    -webkit-text-size-adjust: 100%;
    color: #d9d9d9;
    font-family: DM Sans, sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    pointer-events: none;
    box-sizing: border-box;
    width: 100%;
    perspective: 125rem;
    perspective-origin: 50%;
    justify-content: center;
    align-items: center;
    margin-top: -5vh;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    transform: scale(1.15);
    opacity: 0.3;
    width: 100vw;
    overflow: hidden;

    @media screen and (max-width: 650px) {
        width: 100vh;
    }
}

.background-grid_box-wrapper {
    -webkit-text-size-adjust: 100%;
    color: #d9d9d9;
    font-family: DM Sans, sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    width: 60em;
    height: 60em;
    transform-style: preserve-3d;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    pointer-events: none;
    transform:
        /* perspective(0px) */
        rotateX(45deg) rotateY(0deg) rotateZ(0deg) perspective(1000px);
    animation-name: rotate;
    animation-duration: 80s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    background-color: #000;
}

.background-grid_box {
    -webkit-text-size-adjust: 100%;
    color: #d9d9d9;
    font-family: DM Sans, sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    z-index: 0;
    width: 4em;
    height: 4em;
    border: 3px solid black;
    transition: all .15s;
    position: relative;
    pointer-events: auto;
    border-radius: 10px;
    background-color: #1e06b8;
    margin: 0;
}

.background-grid_box:hover {
    background-color: #02427D;
}

@keyframes rotate {
    from {
        transform:
            /* perspective(0px) */
            rotateX(45deg) rotateY(0deg) rotateZ(0deg) perspective(1000px);
    }

    to {
        transform:
            /* perspective(0px) */
            rotateX(45deg) rotateY(0deg) rotateZ(360deg) perspective(1000px);
    }
}

.hero_cubes-wrapper {
    -webkit-text-size-adjust: 100%;
    color: #d9d9d9;
    font-family: DM Sans, sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    z-index: 3;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    pointer-events: none;
    opacity: 1;
    transition: 0.3s;

    @media screen and (max-width: 650px) {
        display: none;
    }
}

.hero_cubes_item {
    z-index: 5;
    width: 6em;
    height: 6em;
    transform-style: preserve-3d;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    position: absolute;
    top: auto;
    top: 200px;
    left: 200px;
    right: auto;

    @media screen and (max-width: 1440px) {
        top: 160px;
        left: 160px;
    }

    @media screen and (max-width: 1050px) {
        top: 160px;
        left: 100px;
    }
}

.hero_cubes_embed-icon {
    -webkit-text-size-adjust: 100%;
    font-family: DM Sans, sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    pointer-events: none;
    box-sizing: border-box;
    width: 100%;
    opacity: 1;
    color: #fff;
    transform-style: preserve-3d;
    justify-content: center;
    align-items: center;
    display: flex;
    transform: translateZ(-3em);
}

.hero_cubes_embed-icon img {
    width: 100%;
    height: auto;
    position: initial;
}

.hero_cubes_side {
    -webkit-text-size-adjust: 100%;
    color: #d9d9d9;
    font-family: DM Sans, sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    pointer-events: none;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    transform-origin: 50%;
    transform-style: preserve-3d;
    background-color: rgba(255, 255, 255, 0.185);
    border: 2px solid rgba(255, 255, 255, .15);
    justify-content: center;
    align-items: center;
    display: flex;
    transform: translateY(3em)rotateX(90deg)rotateY(0)rotate(0);
}

.hero_cubes_item.is-2 {
    transform-style: preserve-3d;
    top: auto;
    bottom: 150px;
    left: 200px;
    right: auto;

    @media screen and (max-width: 1440px) {
        bottom: 150px;
        left: 160px;
    }

    @media screen and (max-width: 1050px) {
        bottom: 150px;
        left: 100px;
    }
}

.hero_cubes_item.is-3 {
    transform-style: preserve-3d;
    top: auto;
    top: 200px;
    left: auto;
    right: 200px;

    @media screen and (max-width: 1440px) {
        top: 160px;
        right: 160px;
    }

    @media screen and (max-width: 1050px) {
        top: 160px;
        right: 100px;
    }
}

.hero_cubes_item.is-4 {
    transform-style: preserve-3d;
    top: auto;
    bottom: 150px;
    left: auto;
    right: 200px;

    @media screen and (max-width: 1440px) {
        bottom: 150px;
        right: 160px;
    }

    @media screen and (max-width: 1050px) {
        bottom: 150px;
        right: 100px;
    }
}

.hero_cubes_side.is-top {
    transform-style: preserve-3d;
    position: absolute;
    transform: translateY(-3em)rotateX(90deg)rotateY(0)rotate(0);
}

.hero_cubes_side.is-left {
    transform-origin: 50%;
    transform-style: preserve-3d;
    position: absolute;
    transform: translate(-3em)rotateX(0)rotateY(90deg)rotate(0);
}

.hero_cubes_side.is-right {
    transform-origin: 50%;
    transform-style: preserve-3d;
    position: absolute;
    transform: translate(3em)rotateX(0)rotateY(90deg)rotate(0);
}

.hero_cubes_side.is-front {
    transform-origin: 50%;
    transform-style: preserve-3d;
    position: absolute;
    transform: translateZ(3rem)rotate(0);
}

.hero_cubes_side.is-back {
    transform-origin: 50%;
    transform-style: preserve-3d;
    position: absolute;
    transform: translateZ(-3rem)rotate(0);
}