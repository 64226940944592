footer {
    padding: 30px 0;
    padding-bottom: 0;
}

footer .logo-div img {
    position: initial;
    width: auto;
    height: auto;
}

@media screen and (max-width: 1366px) {
    footer .logo-div img {
        width: 300px;
    }
}

@media screen and (max-width: 1050px) {
    footer .logo-div img {
        width: 220px;
    }
}

@media screen and (max-width: 768px) {
    footer .logo-div img {
        width: 180px;
    }
}

footer .linkDiv {
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 15px 20px;
    border-top: 1px solid #ffffff56;
    border-bottom: 1px solid #ffffff56;
    width: max-content;
}

@media screen and (max-width: 1366px) {
    footer .linkDiv {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 768px) {
    footer .linkDiv {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
        border-bottom: none;
        padding-bottom: 0;
    }
}

footer .linkDiv ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 30px;
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 1366px) {
    footer .linkDiv ul {
        column-gap: 20px;
    }
}

@media screen and (max-width: 768px) {
    footer .linkDiv ul {
        column-gap: 10px;
        justify-content: start;
    }
}

footer .linkDiv li {
    display: inline-block;
}

@media screen and (max-width: 768px) {
    footer .linkDiv li {
        width: 100%;
        margin-bottom: 10px;
        text-align: center;
    }
}

footer .linkDiv li a {
    color: #fff;
    text-decoration: none;
    font-size: 20px;
}

@media screen and (max-width: 1366px) {
    footer .linkDiv li a {
        font-size: 20px;
    }
}

@media screen and (max-width: 1050px) {
    footer .linkDiv li a {
        font-size: 16px;
    }
}

footer .footerBottom {
    background-color: #02427D;
    padding: 10px 0;
}

footer .footerBottom p {
    margin: 0;
    color: #fff;
}

@media screen and (max-width: 1366px) {
    footer .footerBottom p {
        font-size: 16px;
    }
}

@media screen and (max-width: 1050px) {
    footer .footerBottom p {
        font-size: 14px;
    }
}

@media screen and (max-width: 500px) {
    footer .footerBottom p {
        font-size: 12px;
    }
}