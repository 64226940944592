.cardMain {
    position: relative;
    width: 100%;
    background-color: #fff;
    padding-bottom: 115px;
    padding: 110px 0;
    padding-bottom: 150px;

    @media screen and (max-width: 650px) {
        padding: 20px 0;
        padding-bottom: 150px;
        background-color: #1a1a1a;
    }
}

.cardMain .card {
    width: 984px;
    height: 554px !important;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky !important;
    top: 200px;
    transition: 0.7s;

    @media screen and (max-width: 1150px) {
        width: 700px;
        height: 400px !important;
    }

    @media screen and (max-width: 800px) {
        width: 80%;
        top: 100px;
    }
}

.cardMain .card.first {
    background-color: #256FC5;
}

.cardMain .card.second {
    background-color: #DA04FE;
}

.cardMain .card.third {
    background-color: #1A0F64;
}

.cardMain .card.fourth {
    background-color: #ECF100;
}

.cardMain .card.fifth {
    background-color: #15B2E2;
}

.cardMain .card.sixth {
    background-color: #01230a;
}

.cardMain .card.seventh {
    background-color: #f4f4f4;
}
.cardMain .card.eigthth {
    background-color: #f4f4f4
}

.cardMain .card.nineth {
    background-color: #fff7f2
}

.cardMain .card.tenth {
    background-color: #0d0e10
}
.cardMain .card.eleventh {
    background-color: #ebebeb
}
.cardMain .card.twelth {
    background-color: #ebfdff
}






.cardMain .heading {
    position: sticky;
    top: 50px;
    display: block;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-content: center;

    @media screen and (max-width: 768px) {
        height: auto;
        top: 20px;
    }
}

.cardMain .heading h2 {
    color: #000;
    text-align: center;
    font-size: 65px;
    font-style: normal;
    font-weight: 700;
    line-height: 106px;
    letter-spacing: 2px;
    text-transform: capitalize;
    position: initial;

    @media screen and (max-width: 1260px) {
        font-size: 45px;
        line-height: 55px;
    }

    @media screen and (max-width: 920px) {
        font-size: 35px;
        line-height: 45px;
    }

    @media screen and (max-width: 650px) {
        color: #fff;
    }
}

.cardMain .logo-design {
    text-align: center;
    width: 100%;
    position: sticky;
    top: 300px;
    margin-bottom: 60px;
}

.cardMain .logo-design img {
    object-fit: cover;
    height: auto;
    width: 80%;
    position: initial;

    @media screen and (max-width: 1260px) {
        width: 100%;
    }
}

.cardMain .card img {
    position: initial;
    height: auto;
    width: 80%;
}

.cardMain .btn-div {
    position: absolute;
    bottom: 50px;
    width: 100%;
    text-align: center;
}

.cardMain .btn-div button {
    width: 249px;
    height: 64px;
    flex-shrink: 0;
    border-radius: 500px;
    border: 2px solid #02427D;
    background-color: transparent;
    color: #02427D;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Jost;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: 0.3s ease-in;

    @media screen and (max-width: 650px) {
        width: auto;
        height: auto;
        padding: 10px 20px;
        color: #fff;
    }
}

.cardMain .btn-div button:hover {
    background-color: #02427D;
    color: #fff;
    font-size: 17px;
}

.cardMain.web {
    background-color: #1a1a1a;
}

.cardMain .card.web {
    background-color: transparent;
    border: none;
    width: 80%;
    top: 150px;
    height: auto !important;
    margin-bottom: 20px;

    img {
        width: 100%;
    }
}

.cardMain .heading.web {
    margin-bottom: 30px;
    height: auto;
    margin-top: 30px;
    top: 30px;
}

.cardMain .heading.web h2 {
    font-size: 28px;
    line-height: 35px;
    color: #fff;
}

.cardMain.web .btn-div button {
    color: #fff;
}