.scrollSection {
    position: relative;
    background-color: #1a1a1a;
    z-index: 3;

    @media screen and (max-width: 650px) {
        background-color: #fff;
    }
}

.scrollSection #progress {
    position: absolute;
    top: 20px;
    left: 20px;
    transform: rotate(-90deg);
}

.scrollSection circle {
    stroke-dashoffset: 0;
    stroke-width: 15%;
    fill: none;
}

.scrollSection .bg {
    stroke: var(--accent);
    opacity: 0.3;
}

.scrollSection #progress .indicator {
    stroke: var(--accent);
}

.stickyContainer {
    position: sticky;
    top: 0;
    height: 100vh;
    overflow: hidden;

    @media screen and (max-width: 650px) {
        height: auto;
    }

    @media screen and (max-width: 650px) {
        position: initial;
        display: flex;
        flex-direction: column-reverse;
        padding: 50px 0;
    }
}

.scrollSection ul {
    position: relative;
    z-index: -1;
}

.scrollSection #scrollUl {
    display: flex;
    list-style: none;
    height: 1188px;
    overflow-x: scroll;
    padding: 20px 0;
    padding-top: 150px;
    flex: 0 0 600px;
    margin: 0 auto;
    position: absolute;
    width: calc(588px * 7 + 350px);
    transition: 0.3s;
    left: 0;
    z-index: 999;
    overflow: hidden;

    @media screen and (max-width: 1260px) {
        padding-top: 200px;
    }

    @media screen and (max-width: 1050px) {
        height: 650px;
    }

    @media screen and (max-width: 650px) {
        display: none;
    }
}

.overLap {
    height: calc(588px * 2 + 180px);
    margin-top: 200px;

    @media screen and (max-width: 1440px) {
        height: calc(488px * 3 + 180px);
    }

    @media screen and (max-width: 1260px) {
        height: calc(488px * 3 + 500px);
    }

    @media screen and (max-width: 1050px) {
        height: calc(488px * 2 + 500px);
    }

    @media screen and (max-width: 650px) {
        height: calc(488px * 3 + 500px);
        display: none;
    }
}

.scrollSection li {
    flex: 0 0 600px;
    height: 65vh;
    margin: 0 0 0 0;
    user-select: none;
    cursor: pointer;
    background-color: transparent;

    @media screen and (max-width: 1440px) {
        flex: 0 0 500px;
        height: 65vh;
    }

    @media screen and (max-width: 1050px) {
        flex: 0 0 400px;
        height: 65vh;
    }
}

.scrollSection #scrollUl li:last-of-type {
    margin: 0;
}

.scrollSection #scrollUl li .rightDiv {
    width: 491px;
    height: 100%;
    background-color: #fff;
    position: relative;
    transition: 0.1s;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 1440px) {
        width: 391px;
    }

    @media screen and (max-width: 1050px) {
        width: 291px;
    }
}

.scrollSection .scrollDiv {
    padding: 0 20px;
    margin: 0 20px;
}

.scrollSection li .rightDiv h1 {
    color: #095DAB;
    font-family: Jost;
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: 106px;
    letter-spacing: 2px;
    text-transform: capitalize;
    position: absolute;
    top: 20px;
    right: -100px;

    @media screen and (max-width: 1440px) {
        // right: -20px;
    }

    @media screen and (max-width: 1050px) {
        font-size: 35px;
        line-height: 55px;
    }
}

.scrollSection .btn-div {
    position: absolute;
    top: 90vh;
    width: 100%;
    text-align: center;
    z-index: 999;

    @media screen and (max-width: 700px) {
        top: 92vh;
    }

    @media screen and (max-width: 650px) {
        position: initial;
    }
}

.scrollSection .btn-div button {
    width: 249px;
    height: 64px;
    flex-shrink: 0;
    border-radius: 500px;
    border: 2px solid #02427D;
    background-color: transparent;
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Jost;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: 0.3s ease-in;

    @media screen and (max-width: 650px) {
        width: auto;
        height: auto;
        padding: 10px 20px;
        color: #1a1a1a;
    }
}

.scrollSection .btn-div button:hover {
    background-color: #02427D;
    font-size: 17px;
    color: #fff;
}

.scrollSection .arrows {
    position: absolute;
    right: 30px;
    top: 50px;
}

#floatingHead {
    color: #FFF;
    font-family: Jost;
    font-size: 70px;
    font-style: normal;
    font-weight: 500;
    line-height: 106px;
    letter-spacing: 2px;
    text-transform: capitalize;
    width: max-content;
    position: absolute;
    left: 0;
    transition: 0.3s;
    top: 30px;

    @media screen and (max-width: 1260px) {
        font-size: 60px;
        top: 50px;
    }

    @media screen and (max-width: 650px) {
        font-size: 40px;
        top: 10px;
    }

    svg {
        @media screen and (max-width: 1260px) {
            width: 60px;
            height: 60px;
        }
    }
}

.scroll-content {
    @media screen and (max-width: 650px) {
        display: none;
    }
}

.mobileSec {
    display: none;
    margin-bottom: 30px;

    @media screen and (max-width: 650px) {
        display: block;
        margin-bottom: 30px;
    }

    .heading {
        position: initial;
        width: 100%;
        margin-bottom: 30px;

        h2 {
            position: initial;
            text-align: center;
            color: #1a1a1a;
            font-family: Jost !important;
            margin: 0;
            margin-bottom: .5rem;
            text-align: center;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 2px;
            text-transform: capitalize;
            font-size: 35px;
        }
    }

    .mobileCaseCard {
        position: relative;
        margin: auto;

        img {
            width: 80%;
            margin: auto;
            height: auto;
            position: initial;
            border-radius: 10px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
    }

    .slick-next {
        right: 0;
        z-index: 99;
    }

    .slick-prev {
        left: 0;
        z-index: 99;
    }

    .slick-next:before{
        content: "";
        width: 20px;
        height: 20px;
        background: url(./assets/images/right.webp);
        background-size: cover;
        background-position: center;
        position: absolute;
        left: 0;
        top: 0;
    }

    .slick-prev:before{
        content: "";
        width: 20px;
        height: 20px;
        background: url(./assets/images/left.webp);
        background-size: cover;
        background-position: center;
        position: absolute;
        left: 0;
        top: 0;
    }
}