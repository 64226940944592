@charset "UTF-8";
body {
  font-family: Jost !important;
  overflow-x: hidden !important; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Jost !important; }

header {
  width: 100%;
  top: 0;
  z-index: 999;
  padding: 20px 0;
  height: max-content;
  background-color: transparent;
  position: relative; }

header .navLinks li {
  display: inline-block;
  color: #FFF; }

header .navLinks li a {
  color: #FFF;
  text-align: right;
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s; }

@media screen and (max-width: 1200px) {
  header .navLinks li a {
    font-size: 12px;
}
 }

header .navLinks li a:hover {
  color: #02427D; }

header .navLinks {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  text-align: right;
  margin: 0;
  column-gap: 30px; }

@media screen and (max-width: 1400px) {
  header .navLinks {
    column-gap: 15px;
} }

@media screen and (max-width: 1050px) {
  header .navLinks {
    display: none; } }

header .logo-div img {
  width: 262px;
  height: auto;
  position: initial; }

@media screen and (max-width: 1260px) {
  header .logo-div img {
    width: 200px; } }

@media screen and (max-width: 600px) {
  header .logo-div img {
    width: 150px; } }

header .nav .logo a {
  font-size: 1.4rem;
  text-decoration: none;
  color: #040404;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 3px; }

header .nav .searchbox {
  display: flex;
  align-items: center;
  border: 1px solid #0a0c0d;
  padding: 0 0.8rem 0 0.8rem; }

header .nav .searchbox input[type=search] {
  border: none;
  background: transparent; }

header .nav .searchbox input[type=search]:focus {
  outline: none;
  border: none; }

header .nav .searchbox button {
  margin-left: 0.8rem; }

header .nav main {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column; }

header .nav main h2 {
  font-size: 4rem;
  margin-bottom: 1rem; }

header .nav main p {
  font-size: 1.7rem;
  font-weight: 300; }

@media screen and (max-height: 450px) {
  header .nav .sidenav {
    padding-top: 15px; }
  header .nav .sidenav a {
    font-size: 18px; } }

header .nav .sidenav a:hover {
  color: #030303; }

header .nav .sidenav .closenav {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 25px;
  margin-left: 50px; }

header .nav .sidenav .closebtn {
  background-color: #fff;
  width: max-content;
  padding: 0;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 30px;
  border-radius: 50px;
  color: #02427D;
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 30px; }

header .nav .sidenav .logo-div {
  position: absolute;
  bottom: 30px;
  width: 100%; }

header .menu-btn {
  cursor: pointer;
  margin-right: 1rem; }

header .nav .sidenav a {
  text-decoration: none;
  font-size: 20px;
  color: #010101;
  display: block;
  padding: 0;
  text-align: center;
  width: max-content;
  margin: auto;
  margin-bottom: 10px;
  color: #fff; }

header .nav .sidenav {
  width: 0;
  overflow-x: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
  padding-top: 1rem;
  height: 100%;
  background: #02427D;
  transition: 0.3s ease-in; }

header .nav .menuIcon {
  cursor: pointer; }

header .nav {
  position: relative;
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: none; }

@media screen and (max-width: 1050px) {
  header .nav {
    display: flex; } }

@media screen and (max-width: 1050px) {
  header .web {
    display: none; } }

@media screen and (max-width: 1260px) {
  header {
    padding: 20px 20px; } }

@media screen and (max-width: 1050px) {
  header {
    overflow: hidden; } }

@media screen and (max-width: 425px) {
  header {
    padding: 10px; } }

.hero-div {
  height: 100vh;
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: 50px;
  background: url(./assets/images/bg.webp);
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  width: 100vw;
  overflow: hidden;
  margin-top: -151px; }
  @media screen and (max-width: 650px) {
    .hero-div {
      height: 80vh;
      padding: 0 10px; } }
  @media screen and (max-width: 600px) {
    .hero-div {
      margin-top: -85px; } }
  .hero-div .iconsDiv {
    position: absolute;
    bottom: 5vh;
    display: flex;
    justify-content: center;
    column-gap: 100px;
    width: 100%;
    flex-wrap: wrap; }
    @media screen and (max-width: 1400px) {
      .hero-div .iconsDiv {
        column-gap: 50px; } }
    @media screen and (max-width: 1050px) {
      .hero-div .iconsDiv {
        column-gap: 50px; } }
    @media screen and (max-width: 1024px) {
      .hero-div .iconsDiv {
        column-gap: 40px; } }
    @media screen and (max-width: 768px) {
      .hero-div .iconsDiv {
        column-gap: 20px;
        bottom: 70px; } }
    .hero-div .iconsDiv img {
      position: initial;
      height: auto;
      width: auto;
      height: 10vh; }
      @media screen and (max-width: 1400px) {
        .hero-div .iconsDiv img {
          height: 100px; } }
      @media screen and (max-width: 1050px) {
        .hero-div .iconsDiv img {
          height: 80px; } }
      @media screen and (max-width: 1024px) {
        .hero-div .iconsDiv img {
          height: 50px; } }
      @media screen and (max-width: 768px) {
        .hero-div .iconsDiv img {
          height: 30px; } }

.hero-div .scrollButton {
  position: absolute;
  bottom: 50px;
  right: 50px;
  width: 120px;
  height: 120px;
  cursor: pointer;
  z-index: 999; }
  @media screen and (max-width: 1260px) {
    .hero-div .scrollButton {
      width: 100px;
      height: 100px;
      bottom: 20px;
      left: auto;
      right: 35px;
      margin-left: auto; } }
  @media screen and (max-width: 920px) {
    .hero-div .scrollButton {
      width: 80px;
      height: 80px;
      bottom: 20px;
      left: auto;
      right: 35px;
      margin-left: auto; } }
  @media screen and (max-width: 500px) {
    .hero-div .scrollButton {
      width: 40px;
      height: 40px;
      bottom: 10px;
      right: 20px; } }
  .hero-div .scrollButton .bounce {
    animation: bounce2 2s ease infinite;
    display: block;
    width: 100%;
    height: 100%;
    padding: 20px; }
  .hero-div .scrollButton .img {
    width: 70%;
    height: 70%;
    position: absolute;
    right: 0;
    opacity: 1;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .hero-div .scrollButton .scrollText {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0;
    animation: rotating 10s linear infinite; }

@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-30px); }
  60% {
    transform: translateY(-15px); } }

canvas {
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.hero-div h1 {
  font-size: 5vw;
  color: #fff;
  position: relative;
  z-index: 999;
  margin: auto;
  text-align: center;
  text-transform: capitalize; }
  @media screen and (max-width: 1260px) {
    .hero-div h1 {
      font-size: 5vw; } }
  @media screen and (max-width: 800px) {
    .hero-div h1 {
      width: 100%;
      font-size: 5vw; } }
  @media screen and (max-width: 650px) {
    .hero-div h1 {
      width: 100%;
      font-size: 34px; } }
  @media screen and (max-width: 570px) {
    .hero-div h1 {
      font-size: 34px;
      width: calc(100% - 20px);
      margin: auto;
      word-spacing: 5px;
      letter-spacing: 1px;
      word-spacing: 2px; }
      .hero-div h1 br {
        display: none; }
      .hero-div h1 .Typewriter {
        display: block; } }

.hero-div h1::after {
  display: none;
  content: "";
  background: url(./assets/images/Vector.webp);
  width: 77px;
  height: 80px;
  position: absolute;
  top: -30px;
  right: 100px;
  transition: 0.7s;
  animation: rotating 10s linear infinite; }
  @media screen and (max-width: 1260px) {
    .hero-div h1::after {
      width: 67px;
      height: 70px;
      background-size: cover;
      right: -30px; } }
  @media screen and (max-width: 900px) {
    .hero-div h1::after {
      display: none; } }

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

.hero-div h1 span {
  color: #fff;
  position: relative; }

.hero-div h1 div {
  display: inline-block; }

.hero-div h1 span::after {
  content: "";
  background: url(./assets/images/h1spanafter.webp);
  width: 100%;
  height: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  bottom: -10px;
  transition: 0.7s; }
  @media screen and (max-width: 900px) {
    .hero-div h1 span::after {
      height: 10px;
      background-size: cover;
      bottom: -5px; } }

.hero-div h5 {
  color: #fff;
  font-size: 3vw;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-align: left;
  background: transparent;
  padding: 0;
  z-index: 999;
  position: relative;
  box-shadow: none;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.37);
  border-radius: 0.375rem;
  padding: 0 1rem;
  width: max-content;
  margin: auto;
  transition: 0.3s;
  margin-bottom: 20px; }
  .hero-div h5 span {
    font-size: 3vw;
    font-weight: 700;
    font-family: Jost !important; }
  @media screen and (max-width: 800px) {
    .hero-div h5 {
      font-size: 3vw; }
      .hero-div h5 span {
        font-size: 3vw;
        font-weight: 700; } }
  @media screen and (max-width: 650px) {
    .hero-div h5 {
      font-size: 16px; }
      .hero-div h5 span {
        font-size: 16px;
        font-weight: 700; } }

.hero-div h5:hover {
  background-color: #fff;
  color: #02427D;
  font-size: 42px;
  margin-bottom: 0; }
  .hero-div h5:hover span {
    font-size: 42px;
    font-weight: 700;
    font-family: Jost !important; }
  @media screen and (max-width: 800px) {
    .hero-div h5:hover {
      font-size: 32px; }
      .hero-div h5:hover span {
        font-size: 32px;
        font-weight: 700; } }
  @media screen and (max-width: 650px) {
    .hero-div h5:hover {
      font-size: 22px; }
      .hero-div h5:hover span {
        font-size: 22px;
        font-weight: 700; } }

.what-we-do {
  padding: 50px 0;
  position: relative;
  background-color: #fff; }
  @media screen and (max-width: 650px) {
    .what-we-do {
      padding: 20px 0; } }

.what-we-do .single {
  padding: 50px 0;
  position: relative;
  cursor: pointer;
  z-index: 2; }
  @media screen and (max-width: 992px) {
    .what-we-do .single {
      padding: 30px 0; } }

.what-we-do .single .img-abs {
  position: absolute;
  top: 0;
  left: 30%;
  transition: 0.7s;
  z-index: 1; }
  @media screen and (max-width: 650px) {
    .what-we-do .single .img-abs {
      display: none; } }

.what-we-do .single .img-abs img {
  width: 200px;
  height: auto;
  z-index: -1;
  box-shadow: 0px 0px 11px 0px #00000059;
  transition: 0.7s;
  opacity: 0; }

.what-we-do .single:hover .img-abs {
  left: 60%;
  z-index: 2; }

.what-we-do .single:hover .img-abs img {
  opacity: 1;
  z-index: 3; }

.what-we-do .single2 {
  cursor: url(./assets/images/web2.webp), pointer; }

.what-we-do .single3 {
  cursor: url(./assets/images/web3.webp), pointer; }

.what-we-do .single4 {
  cursor: url(./assets/images/web4.webp), pointer; }

.what-we-do hr {
  margin: 0; }

.what-we-do .heading h6 {
  font-size: 30px;
  margin: 0;
  color: #02427D; }
  @media screen and (max-width: 992px) {
    .what-we-do .heading h6 {
      text-align: center; } }
  @media screen and (max-width: 650px) {
    .what-we-do .heading h6 {
      margin-bottom: 0px;
      margin-top: 20px; } }

.what-we-do .heading h2 {
  font-size: 65px;
  margin: 0;
  position: initial; }
  @media screen and (max-width: 1260px) {
    .what-we-do .heading h2 {
      font-size: 45px; } }
  @media screen and (max-width: 992px) {
    .what-we-do .heading h2 {
      text-align: center;
      margin-bottom: 20px; } }
  @media screen and (max-width: 650px) {
    .what-we-do .heading h2 {
      font-size: 35px;
      margin-bottom: 10px; } }

.what-we-do .text-div p {
  font-size: 22px;
  margin: 0; }
  @media screen and (max-width: 992px) {
    .what-we-do .text-div p {
      text-align: center; } }
  @media screen and (max-width: 650px) {
    .what-we-do .text-div p {
      font-size: 18px; } }

.App {
  font-family: sans-serif;
  text-align: center; }

.top {
  min-height: 120vh;
  background-color: lightblue; }

.bottom {
  min-height: 150vh;
  background-color: lightblue; }

#output {
  margin-top: 2rem; }

#hscroll {
  height: 100vh;
  color: red; }

.card {
  height: 500px;
  width: 500px;
  margin: auto;
  border-radius: 10px;
  transition: 0.7s; }

#card1 {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
  background-color: red; }

#card2 {
  position: absolute;
  top: 570px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
  background-color: blue; }

#card3 {
  position: absolute;
  top: 1090px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 3;
  background: black; }

#card4 {
  position: absolute;
  top: 1610px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 4;
  background-color: green; }

#card5 {
  position: absolute;
  top: 2130px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 5;
  background-color: blueviolet; }

/* image change on scroll css  */
body {
  background: #fff !important;
  height: 100vh;
  overflow: scroll;
  transition-timing-function: ease-out; }

.section1 {
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll; }

.section1::-webkit-scrollbar {
  display: none; }

.section {
  align-items: flex-start;
  display: flex;
  position: relative; }

.viewMoreBtn {
  position: sticky;
  width: 135px;
  height: 135px;
  top: 80vh;
  left: 215px;
  z-index: 999;
  transition: 0.7s;
  animation: rotating 20s linear infinite;
  cursor: pointer; }
  @media screen and (max-width: 1260px) {
    .viewMoreBtn {
      width: 100px;
      height: 100px;
      top: 85vh;
      left: auto;
      right: 35px;
      margin-left: auto; } }
  @media screen and (max-width: 920px) {
    .viewMoreBtn {
      width: 80px;
      height: 80px;
      top: 85vh;
      left: auto;
      right: 35px;
      margin-left: auto; } }
  @media screen and (max-width: 650px) {
    .viewMoreBtn {
      display: none; } }

.viewMoreBtn img {
  position: initial;
  width: 100%;
  height: 100%; }

.webDesignMain {
  background: #1a1a1a; }
  @media screen and (max-width: 650px) {
    .webDesignMain {
      background-size: 100% auto !important;
      background-repeat: no-repeat !important;
      background-attachment: fixed !important;
      background-position: center !important;
      width: 100vw;
      overflow-y: hidden; } }

.webDesignMain .overLay {
  display: none; }
  @media screen and (max-width: 650px) {
    .webDesignMain .overLay {
      width: 100vw;
      height: 100%;
      background-color: #00000044;
      position: absolute;
      top: 0;
      display: block; } }

.section .div1 {
  flex-direction: column;
  flex: 0 1 50%;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 650px) {
    .section .div1 {
      display: none; } }

.section .div2 {
  flex: 0 1 50%; }
  @media screen and (max-width: 650px) {
    .section .div2 {
      flex: 0 1 100%; } }

.section .div2 .list {
  grid-column-gap: 3rem;
  flex-flow: column wrap;
  display: flex; }

.section .div2 .list .services_item-content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: auto; }

.section .div2 .list .services_item-content h1 {
  margin-bottom: 20px;
  font-size: 90px;
  color: #fff; }
  @media screen and (max-width: 1440px) {
    .section .div2 .list .services_item-content h1 {
      font-size: 70px; } }
  @media screen and (max-width: 1050px) {
    .section .div2 .list .services_item-content h1 {
      font-size: 50px; } }
  @media screen and (max-width: 1050px) {
    .section .div2 .list .services_item-content h1 {
      text-align: center; } }
  @media screen and (max-width: 650px) {
    .section .div2 .list .services_item-content h1 {
      font-size: 40px; } }

.section .div1 img {
  width: 80%;
  height: auto;
  transition: 0.7s; }
  @media screen and (max-width: 1150px) {
    .section .div1 img {
      width: 90%;
      margin-left: auto; } }

/* parralax view */
body {
  --black: #000000;
  --ash-black: #222;
  --white: #fafafa;
  --sky: #00ccff;
  --green: #22dddd;
  --blue: #1300ff;
  --dusk: #6600ff;
  --purple: #9900ff;
  --pink: #ff0066;
  --red: #fe0222;
  --orange: #fd7702;
  --yellow: #ffbb00;
  --background: black;
  --accent: var(--white);
  margin: 0;
  padding: 0;
  background-color: var(--background) !important;
  color: var(--accent); }

* {
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased; }

h1,
h2,
h3 {
  font-weight: 600;
  font-style: normal; }

h1 {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1.2;
  /* text-align: center; */
  margin: 100px 0 40px; }

h2 {
  font-weight: 400;
  margin: 50px 0 10px; }

p {
  margin: 0 0 30px 0;
  font-size: 18px; }

code {
  font-family: input-mono, monospace;
  font-weight: 400;
  font-style: normal; }

@media screen and (min-width: 650px) {
  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: var(--background); } }

@media screen and (min-width: 650px) {
  ::-webkit-scrollbar-thumb {
    background: var(--accent);
    -webkit-border-radius: 1ex; } }

@media screen and (min-width: 650px) {
  ::-webkit-scrollbar-corner {
    background: var(--background); } }

html {
  /* scroll-snap-type: y mandatory; */ }

h1 {
  font-size: 56px;
  font-weight: 700;
  letter-spacing: -3px;
  line-height: 1.2;
  /* text-align: center; */
  margin: 100px 0; }

h2 {
  margin: 0;
  color: var(--accent);
  left: calc(50% + 130px);
  font-size: 56px;
  font-weight: 700;
  letter-spacing: -3px;
  line-height: 1.2;
  position: absolute; }

.sectionPara {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  scroll-snap-align: center;
  perspective: 500px; }

.sectionPara > div {
  width: 300px;
  height: 400px;
  position: relative;
  max-height: 90vh;
  margin: 20px;
  background: var(--white);
  overflow: hidden; }

.progress {
  position: fixed;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--accent);
  bottom: 100px; }

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.281);
  z-index: 99; }

.main {
  overflow-x: hidden;
  display: flex; }

.main h1 {
  margin: 0;
  padding: 0; }

.main .sec {
  min-width: 50vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4ch; }

.main .sec:nth-child(even) {
  background-color: teal;
  color: white; }

.brandingSection {
  background-color: #1a1a1a;
  position: relative;
  z-index: 6;
  padding: 110px 0; }
  @media screen and (max-width: 650px) {
    .brandingSection {
      padding: 30px 0; } }

.brandingSection .heading {
  margin-bottom: 50px; }
  @media screen and (max-width: 650px) {
    .brandingSection .heading {
      margin-bottom: 00px; } }

.brandingSection.uiux .heading {
  margin-bottom: 50px; }
  @media screen and (max-width: 650px) {
    .brandingSection.uiux .heading {
      margin-bottom: 30px; } }

.brandingSection .heading h2 {
  font-size: 65px;
  margin: 0;
  position: initial;
  text-align: center;
  color: #fff; }
  @media screen and (max-width: 1260px) {
    .brandingSection .heading h2 {
      font-size: 45px; } }
  @media screen and (max-width: 920px) {
    .brandingSection .heading h2 {
      font-size: 35px; } }

.brandingSection.socialMediaSection .heading h2 {
  color: #1a1a1a; }

.brandingSection .brandDiv {
  margin-bottom: 50px;
  cursor: pointer;
  position: relative; }

.brandingSection .brandDiv .img-div {
  position: relative; }

.brandingSection .brandDiv .img-div img {
  position: initial;
  transition: 0.3s;
  object-fit: cover;
  width: 100%;
  height: auto; }

.brandingSection .brandDiv .heading {
  margin-top: 30px; }

.brandingSection .brandDiv .heading h4 {
  color: #fff;
  font-family: Jost;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  text-transform: capitalize; }
  @media screen and (max-width: 650px) {
    .brandingSection .brandDiv .heading h4 {
      font-size: 24px;
      line-height: 24px;
      text-align: center; } }

.brandingSection .brandDiv .borderDiv {
  position: absolute;
  border: 20px solid #ffffff83;
  width: 0%;
  height: 0%;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.7s cubic-bezier(0.215, 0.61, 0.355, 1); }

.brandingSection .brandDiv .borderDiv.two {
  transition: 1.2s cubic-bezier(0.215, 0.61, 0.355, 1); }

.brandingSection .brandDiv:hover .borderDiv {
  width: calc(100%);
  height: calc(100%);
  opacity: 0.5; }

.brandingSection .btn-div {
  text-align: center;
  margin-top: 70px; }
  @media screen and (max-width: 650px) {
    .brandingSection .btn-div {
      margin-top: 0px; } }

.brandingSection .btn-div button {
  width: 249px;
  height: 64px;
  flex-shrink: 0;
  border-radius: 500px;
  border: 2px solid #02427D;
  color: #000;
  text-align: center;
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  transition: 0.3s; }
  @media screen and (max-width: 650px) {
    .brandingSection .btn-div button {
      width: auto;
      height: auto;
      padding: 10px 20px; } }

.brandingSection.uiux .btn-div button {
  color: #fff; }

.brandingSection .btn-div button:hover {
  font-size: 17px;
  background-color: #02427D;
  color: #fff; }

.brandingSection.socialMediaSection {
  background-color: #fff; }

.brandingSection.partnerSection {
  background-color: #fff;
  padding-bottom: 0z; }

.brandingSection.partnerSection .heading {
  margin-bottom: 20px; }

.brandingSection.partnerSection .heading h2 {
  color: #1a1a1a; }

.brandingSection.partnerSection .text-div {
  width: 60%;
  margin: auto; }
  @media screen and (max-width: 1050px) {
    .brandingSection.partnerSection .text-div {
      width: 80%; } }
  @media screen and (max-width: 768px) {
    .brandingSection.partnerSection .text-div {
      width: 100%; } }

.brandingSection.partnerSection .text-div p {
  font-size: 20px; }
  @media screen and (max-width: 768px) {
    .brandingSection.partnerSection .text-div p {
      font-size: 16px; } }

.brandingSection.partnerSection .partnerLogo img {
  position: initial;
  width: 100%;
  height: auto;
  filter: grayscale(100%);
  transition: 0.3s; }

.brandingSection.partnerSection .partnerLogo img:hover {
  filter: grayscale(0%); }

.brandingSection.partnerSection .parnerSlider {
  margin-top: 50px; }

.brandingSection.socialMediaSection .brandDiv .heading h4 {
  color: #1a1a1a; }

.bottom-shadow {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, black 40%);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0; }

.top-shadow {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, black 25%);
  height: 100%;
  width: 140%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2; }

.slideContent {
  width: 400px;
  height: 450px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: transform 0.5s ease-in-out;
  opacity: 0.7;
  display: grid;
  align-content: start;
  padding-top: 10px;
  transform-style: preserve-3d;
  transform: perspective(1000px) translateX(calc(100% * var(--offset))) rotateY(calc(-45deg * var(--dir)));
  border-radius: 15px; }
  @media screen and (max-width: 600px) {
    .slideContent {
      width: 300px;
      height: 350px; } }
  @media screen and (max-width: 350px) {
    .slideContent {
      width: 280px;
      height: 330px; } }

.slideContentInner {
  transform-style: preserve-3d;
  transform: translateZ(2rem);
  transition: opacity 0.3s linear;
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  opacity: 0; }
  @media screen and (max-width: 650px) {
    .slideContentInner {
      transform: none; } }

.slideContentInner .slideSubtitle,
.slideContentInner .slideTitle {
  font-size: 2rem;
  font-weight: normal;
  letter-spacing: 0.2ch;
  text-transform: capitalize;
  margin: 0;
  width: 100%;
  left: 0;
  background: transparent;
  box-shadow: none;
  text-align: left;
  color: #02427D;
  font-weight: bold;
  padding: 0; }
  @media screen and (max-width: 650px) {
    .slideContentInner .slideSubtitle,
    .slideContentInner .slideTitle {
      text-align: center; } }

.slideContentInner .slideSubtitle::before {
  content: "— "; }

.slideContentInner .slideDescription {
  margin: 0;
  font-size: 0.8rem;
  letter-spacing: 0.2ch; }
  @media screen and (max-width: 650px) {
    .slideContentInner .slideDescription {
      text-align: center; } }

.slideBackground {
  position: fixed !important;
  top: 0;
  left: -10%;
  right: -10%;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s linear, transform 0.3s ease-in-out;
  pointer-events: none;
  transform: translateX(calc(10% * var(--dir))); }

.slide[data-active] {
  z-index: 2;
  pointer-events: auto; }

.slide[data-active] .slideBackground {
  opacity: 0.1;
  transform: none; }

.slide[data-active] .slideContentInner {
  opacity: 1; }

.slide[data-active] .slideContent {
  --x: calc(var(--px) - 0.5);
  --y: calc(var(--py) - 0.5);
  opacity: 1;
  transform-style: preserve-3d;
  transform: perspective(1000px) translateX(calc(100% * var(--offset)));
  transition: transform 0.5s ease-in-out; }

.slide[data-active] .slideContent:hover {
  transition: none;
  transform: perspective(1000px) rotateY(calc(var(--x) * 45deg)) rotateX(calc(var(--y) * -45deg)); }

.slidesWrapper {
  min-height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%; }
  @media screen and (max-width: 650px) {
    .slidesWrapper {
      min-height: auto;
      margin: 30px 0; } }
  @media screen and (max-width: 350px) {
    .slidesWrapper {
      min-height: auto; } }

/* @media (min-width: 768px) {
  .slidesWrapper {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .slidesWrapper {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .slidesWrapper {
    width: 1170px;
  }
} */
.slidesWrapper *,
.slidesWrapper *::before,
.slidesWrapper *::after {
  position: relative; }

.slides {
  display: grid; }

.slides > .slide {
  grid-area: 1 / -1; }

.slides > .prevSlideBtn,
.slides > .nextSlideBtn {
  appearance: none;
  background: transparent;
  border: none;
  color: #000;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  position: absolute;
  font-size: 2rem;
  width: 5rem;
  height: 5rem;
  top: 50%;
  transform: translate(0%, -50%);
  transition: opacity 0.3s;
  opacity: 0.7;
  z-index: 5; }

.slides > .prevSlideBtn:hover,
.slides > .nextSlideBtn:hover {
  opacity: 1; }

.slides > .prevSlideBtn:focus,
.slides > .nextSlideBtn:focus {
  outline: none; }

.slides > .prevSlideBtn {
  left: -50%; }
  @media screen and (max-width: 768px) {
    .slides > .prevSlideBtn {
      left: -55px; } }
  @media screen and (max-width: 350px) {
    .slides > .prevSlideBtn {
      left: -45px; } }

.slides > .nextSlideBtn {
  right: -50%; }
  @media screen and (max-width: 768px) {
    .slides > .nextSlideBtn {
      right: -55px; } }
  @media screen and (max-width: 350px) {
    .slides > .nextSlideBtn {
      right: -45px; } }

.gradientsep {
  background-image: linear-gradient(to right, #4755690a, #9d0cb28a, #4343c899, #4755690a);
  width: 80%;
  height: 0.094rem;
  content: "";
  box-shadow: 0px 0.125rem 0.75rem #4343c84d;
  margin: auto;
  margin-top: 80px; }

/* Header */
.large-header {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  position: absolute;
  height: 100% !important; }
  @media screen and (max-width: 768px) {
    .large-header {
      display: none; } }

.heroDivNew {
  background-color: #000;
  margin-top: -150px;
  height: 1000px;
  display: flex;
  align-items: center;
  position: relative; }
  @media screen and (max-width: 1400px) {
    .heroDivNew {
      height: 800px; } }
  @media screen and (max-width: 992px) {
    .heroDivNew {
      height: auto;
      padding: 200px 0;
      padding-bottom: 0; } }
  @media screen and (max-width: 600px) {
    .heroDivNew {
      margin-top: -105px; } }
  @media screen and (max-width: 500px) {
    .heroDivNew {
      height: 600px;
      padding-top: 80px; } }
  .heroDivNew .container {
    position: relative; }
  .heroDivNew h1 {
    color: #fff;
    letter-spacing: -1px;
    margin-bottom: 0;
    font-size: 88px;
    font-weight: 500;
    line-height: 96px; }
    @media screen and (max-width: 1400px) {
      .heroDivNew h1 {
        font-size: 68px;
        line-height: 76px; } }
    @media screen and (max-width: 1250px) {
      .heroDivNew h1 {
        font-size: 48px;
        line-height: 56px; } }
    @media screen and (max-width: 768px) {
      .heroDivNew h1 {
        font-size: 32px;
        line-height: 38px; } }
    @media screen and (max-width: 500px) {
      .heroDivNew h1 {
        font-size: 28px;
        line-height: 34px; } }
    .heroDivNew h1 .circleAnimation {
      display: inline-block;
      width: 62px;
      height: 66px;
      border: 10px solid #fff;
      border-radius: 30px;
      flex-direction: column;
      transition: 0.7s;
      margin-left: 5px; }
      @media screen and (max-width: 1400px) {
        .heroDivNew h1 .circleAnimation {
          width: 45px;
          height: 48px; } }
      @media screen and (max-width: 1250px) {
        .heroDivNew h1 .circleAnimation {
          width: 30px;
          height: 35px;
          border-width: 5px; } }
      @media screen and (max-width: 768px) {
        .heroDivNew h1 .circleAnimation {
          height: 23px;
          width: 20px; } }
      @media screen and (max-width: 450px) {
        .heroDivNew h1 .circleAnimation {
          height: 20px;
          margin-left: 2px;
          margin-right: 2px;
          border-width: 4px; } }
  .heroDivNew .flexDiv {
    display: flex;
    column-gap: 30px;
    flex-wrap: wrap; }
    .heroDivNew .flexDiv .sideDiv h1 {
      outline-offset: 0px;
      border: 1px solid #fff;
      border-radius: 50px;
      outline: 1px #fff;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-left: 16px;
      padding-left: 8px;
      padding-right: 32px;
      display: flex;
      overflow: hidden;
      margin-bottom: 10px;
      width: max-content; }
      @media screen and (max-width: 768px) {
        .heroDivNew .flexDiv .sideDiv h1 {
          margin-left: 20px; } }
      @media screen and (max-width: 500px) {
        .heroDivNew .flexDiv .sideDiv h1 {
          margin-left: 0; } }
      .heroDivNew .flexDiv .sideDiv h1.two {
        margin-left: 50px; }
        @media screen and (max-width: 768px) {
          .heroDivNew .flexDiv .sideDiv h1.two {
            margin-left: 30px; } }
        @media screen and (max-width: 500px) {
          .heroDivNew .flexDiv .sideDiv h1.two {
            margin-left: 0; } }
      .heroDivNew .flexDiv .sideDiv h1 .slicica_aset {
        display: flex;
        align-items: center;
        margin-right: 10px; }
      .heroDivNew .flexDiv .sideDiv h1 svg {
        height: 80px; }
        @media screen and (max-width: 1400px) {
          .heroDivNew .flexDiv .sideDiv h1 svg {
            width: 60px;
            height: 60px; } }
        @media screen and (max-width: 1250px) {
          .heroDivNew .flexDiv .sideDiv h1 svg {
            width: 40px;
            height: 40px; } }
        @media screen and (max-width: 768px) {
          .heroDivNew .flexDiv .sideDiv h1 svg {
            height: 30px;
            width: 30px; } }
  .heroDivNew .flex-div1 {
    display: flex;
    width: 100%;
    margin-left: 50px; }
    @media screen and (max-width: 992px) {
      .heroDivNew .flex-div1 {
        margin-left: 10px; } }
    @media screen and (max-width: 768px) {
      .heroDivNew .flex-div1 {
        flex-direction: column; } }
  @media screen and (max-width: 768px) and (max-width: 768px) {
    .heroDivNew .flex-div1 .sideDiv {
      margin-top: 20px; } }
    @media screen and (max-width: 768px) {
        .heroDivNew .flex-div1 .sideDiv h1 {
          margin-left: 0; } }
    @media screen and (max-width: 500px) {
      .heroDivNew .flex-div1 {
        margin-left: 0; } }
  .heroDivNew .videoDiv {
    position: absolute;
    width: 500px;
    bottom: -180px; }
    @media screen and (max-width: 1400px) {
      .heroDivNew .videoDiv {
        bottom: -150px;
        width: 400px; } }
    @media screen and (max-width: 1250px) {
      .heroDivNew .videoDiv {
        bottom: -200px; } }
        @media screen and (max-width: 1399px) {
          header .navLinks li a {
            font-size: 12px;
        }

             }
    @media screen and (max-width: 992px) {
      .heroDivNew .videoDiv {
        width: 50%;
        position: initial;
        margin: auto; } }

#eiJo4rZE7fS2_tr {
  animation: eiJo4rZE7fS2_tr__tr 3900ms linear infinite alternate-reverse forwards; }

@keyframes eiJo4rZE7fS2_tr__tr {
  0% {
    transform: translate(40.74795px, 48px) rotate(0deg); }
  41.025641% {
    transform: translate(40.74795px, 48px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  61.538462% {
    transform: translate(40.74795px, 48px) rotate(360deg); }
  100% {
    transform: translate(40.74795px, 48px) rotate(360deg); } }

#eiJo4rZE7fS4 {
  animation: eiJo4rZE7fS4__m 3900ms linear infinite alternate-reverse forwards; }

@keyframes eiJo4rZE7fS4__m {
  0% {
    d: path("M25,41L41.6397,41C51.2042,41,59,33.3035,59,23.8607L59,23.8607L59,23.8607C59,33.3035,66.7958,41,76.3603,41L93,41L76.3603,41C66.7958,41,59,48.6965,59,58.1393L59,58.1393L59,58.1393C59,48.6965,51.2042,41,41.6397,41L25,41Z");
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  35.897436% {
    d: path("M25,41L41.6397,41C51.2042,41,59,33.3035,59,23.8607L59,15L59,23.8607C59,33.3035,66.7958,41,76.3603,41L93,41L76.3603,41C66.7958,41,59,48.6965,59,58.1393L59,67L59,58.1393C59,48.6965,51.2042,41,41.6397,41L25,41Z");
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  66.666667% {
    d: path("M25,41L41.6397,41C51.2042,41,59,33.3035,59,23.8607L59,15L59,23.8607C59,33.3035,66.7958,41,76.3603,41L93,41L76.3603,41C66.7958,41,59,48.6965,59,58.1393L59,67L59,58.1393C59,48.6965,51.2042,41,41.6397,41L25,41Z");
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  100% {
    d: path("M25,41L41.6397,41C51.2042,41,59,33.3035,59,23.8607L59,23.8607L59,23.8607C59,33.3035,66.7958,41,76.3603,41L93,41L76.3603,41C66.7958,41,59,48.6965,59,58.1393L59,58.1393L59,58.1393C59,48.6965,51.2042,41,41.6397,41L25,41Z"); } }

#eiJo4rZE7fS4_to {
  animation: eiJo4rZE7fS4_to__to 3900ms linear infinite alternate-reverse forwards; }

@keyframes eiJo4rZE7fS4_to__to {
  0% {
    transform: translate(59px, 41px); }
  10.25641% {
    transform: translate(59px, 41px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  35.897436% {
    transform: translate(40.74795px, 41px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  66.666667% {
    transform: translate(40.74795px, 41px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  89.74359% {
    transform: translate(59px, 41px); }
  100% {
    transform: translate(59px, 41px); } }

#eiJo4rZE7fS6_to {
  animation: eiJo4rZE7fS6_to__to 3900ms linear infinite alternate-reverse forwards; }

@keyframes eiJo4rZE7fS6_to__to {
  0% {
    transform: translate(105px, 41px); }
  10.25641% {
    transform: translate(105px, 41px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  35.897436% {
    transform: translate(68.494px, 41px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  66.666667% {
    transform: translate(68.494px, 41px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  89.74359% {
    transform: translate(105px, 41px); }
  100% {
    transform: translate(105px, 41px); } }

#eiJo4rZE7fS7_to {
  animation: eiJo4rZE7fS7_to__to 3900ms linear infinite alternate-reverse forwards; }

@keyframes eiJo4rZE7fS7_to__to {
  0% {
    transform: translate(77.25395px, 41px); }
  10.25641% {
    transform: translate(77.25395px, 41px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  35.897436% {
    transform: translate(40.74795px, 41px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  66.666667% {
    transform: translate(40.74795px, 41px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  89.74359% {
    transform: translate(77.25395px, 41px); }
  100% {
    transform: translate(77.25395px, 41px); } }

#ezQX1Cbfag73_to {
  animation: ezQX1Cbfag73_to__to 8200ms linear infinite normal forwards; }

@keyframes ezQX1Cbfag73_to__to {
  0% {
    transform: translate(41px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  15.853659% {
    transform: translate(47px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  21.95122% {
    transform: translate(47px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  35.365854% {
    transform: translate(41px, 42px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  41.463415% {
    transform: translate(41px, 42px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  56.097561% {
    transform: translate(35px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  62.195122% {
    transform: translate(35px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  76.829268% {
    transform: translate(41px, 54px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  82.926829% {
    transform: translate(41px, 54px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  97.560976% {
    transform: translate(41px, 48px); }
  100% {
    transform: translate(41px, 48px); } }

#ezQX1Cbfag74_to {
  animation: ezQX1Cbfag74_to__to 8200ms linear infinite normal forwards; }

@keyframes ezQX1Cbfag74_to__to {
  0% {
    transform: translate(41px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  15.853659% {
    transform: translate(53px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  21.95122% {
    transform: translate(53px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  35.365854% {
    transform: translate(41px, 36px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  41.463415% {
    transform: translate(41px, 36px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  56.097561% {
    transform: translate(29px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  62.195122% {
    transform: translate(29px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  76.829268% {
    transform: translate(41px, 60px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  82.926829% {
    transform: translate(41px, 60px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  97.560976% {
    transform: translate(41px, 48px); }
  100% {
    transform: translate(41px, 48px); } }

#ezQX1Cbfag75_to {
  animation: ezQX1Cbfag75_to__to 8200ms linear infinite normal forwards; }

@keyframes ezQX1Cbfag75_to__to {
  0% {
    transform: translate(41px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  15.853659% {
    transform: translate(59px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  21.95122% {
    transform: translate(59px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  35.365854% {
    transform: translate(41px, 30px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  41.463415% {
    transform: translate(41px, 30px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  56.097561% {
    transform: translate(23px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  62.195122% {
    transform: translate(23px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  76.829268% {
    transform: translate(41px, 66px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  82.926829% {
    transform: translate(41px, 66px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  97.560976% {
    transform: translate(41px, 48px); }
  100% {
    transform: translate(41px, 48px); } }

#ezQX1Cbfag76_to {
  animation: ezQX1Cbfag76_to__to 8200ms linear infinite normal forwards; }

@keyframes ezQX1Cbfag76_to__to {
  0% {
    transform: translate(41px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  15.853659% {
    transform: translate(65px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  21.95122% {
    transform: translate(65px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  35.365854% {
    transform: translate(41px, 24px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  41.463415% {
    transform: translate(41px, 24px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  56.097561% {
    transform: translate(17px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  62.195122% {
    transform: translate(17px, 48px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  76.829268% {
    transform: translate(41px, 72px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  82.926829% {
    transform: translate(41px, 72px);
    animation-timing-function: cubic-bezier(0.6, 0.6, 0, 1); }
  97.560976% {
    transform: translate(41px, 48px); }
  100% {
    transform: translate(41px, 48px); } }


    .dropdown {
      position: relative;
    }
    .dropdown .dropdown-menu {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      background-color: #fff; /* Background color for the dropdown menu */
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Box shadow for the dropdown */
      z-index: 1;
    }
    .dropdown:hover .dropdown-menu {
      display: block; /* Show the dropdown menu on hover */
      display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: #02427D;
    padding: 10px;
    text-align: left;
    row-gap: 5px;
    }
    .dropdown-menu li {
      padding: 10px;
      border-bottom: 1px solid #ddd; /* Add a border between dropdown items */
    }
    .dropdown-menu li:last-child {
      border-bottom: none; /* Remove the border from the last item */
    }

    ul.dropdown-menu li a:hover {
      color: white;
  }


// .sidenav .mobile-drop-down-menu ol, ul {
//   padding-left: 0rem !important;
// }
// .sidenav ul.mobile-drop-down-menu-box {
//   opacity: 0 !important;
// }
// .sidenav a.mobile-drop-down-menu:hover ul.mobile-drop-down-menu-box{
//   opacity: 1 !important;
// }
// header a.mobile-drop-down-menu:hover ul.mobile-drop-down-menu-box {
//   color: #ff0000 !important;
//   opacity: 1 !important;
// }
// ul.mobile-drop-down-menu-box li a {
//   font-size: 16px !important;
//   margin-bottom: 5px !important;
// }

.mobile-drop-down-menu-box {
  display: none; /* Hide the menu by default */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  /* Style for the dropdown button */
  .mobile-drop-down-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
  }

  /* Style for the dropdown icon */
  .mobile-drop-down-menu i {
    transition: transform 0.3s;
  }

  /* Style for the dropdown menu items */
  .mobile-drop-down-menu-box {
    display: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    list-style: none;
  }
  
  /* Show the dropdown menu when the dropdown button is clicked */
  .mobile-drop-down-menu.active + ul {
    display: block;
  }

  /* Rotate the dropdown icon when the menu is open */
  .mobile-drop-down-menu.active i {
    transform: rotate(180deg);
  }
}
.mobile-drop-down-container{
  position: relative;
}
.mobile-drop-down-container i.mobile-drop-down-icon {
  color: white;
  margin-top: -10px;
}
ul.mobile-drop-down-menu-box {
  list-style: none;
  padding-left: 0;
}
.mobile-drop-down-container .mobile-menu-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-drop-down-container a.mobile-drop-down-link {
  display: inline-block !important;
  margin-right: 10px !important;
  margin-left: 0 !important;
}
ul.mobile-drop-down-menu-box {
  padding-top: 0;
  padding-right: 0;
  box-shadow: none !important;
}
ul.mobile-drop-down-menu-box li a{
  font-size: 16px !important;
  margin-bottom: 5px !important;
}
// @media (max-width: 920px){
//   .mobile-drop-down-container i.mobile-drop-down-icon {
//     right: 39%;
// }
// }
// @media (max-width: 768px){
//   .mobile-drop-down-container i.mobile-drop-down-icon {
//     right: 37%;
// }
// }
// @media (max-width: 700px){
//   .mobile-drop-down-container i.mobile-drop-down-icon {
//     right: 34%;
// }
// }
// @media (max-width: 600px){

// }